<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="suppliers"
    :search="search"
    :options.sync="options"
    :loading="loading"
    sort-by="code"
    class="elevation-1"
    item-key="code"
    show-select
    :footer-props="{
      showFirstLastPage: true
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>供应商管理</v-toolbar-title>
        <v-divider inset vertical class="mx-4"></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="查找"
          single-line
          hide-details
        ></v-text-field>
        <v-divider inset vertical class="mx-4"></v-divider>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle dense>
              <v-btn class="mb-2" v-bind="attrs" v-on="on">
                <v-icon color="green">mdi-file-outline</v-icon>
                新建
              </v-btn>
              <v-btn class="mb-2" disabled>
                <v-icon color="orange">mdi-delete</v-icon>
                删除
              </v-btn>
              <v-btn
                class="mb-2"
                :loading="downloadLoading"
                @click="export2Excel"
              >
                <v-icon color="blue">mdi-file-export-outline</v-icon>
                导出
              </v-btn>
            </v-btn-toggle>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.code"
                      label="代码"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.short_name"
                      label="缩写"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name_cn"
                      label="中文名称"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name_en"
                      label="英文名称"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.address_cn"
                      label="中文地址"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.address_en"
                      label="英文地址"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.contact"
                      label="联系人"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="8">
                    <v-text-field
                      v-model="editedItem.email"
                      label="电子邮箱"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.mobile_phone"
                      label="手机"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.phone"
                      label="电话"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.fax"
                      label="传真"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.website"
                      label="网址"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="blue darken-1" @click="close">
                取消
              </v-btn>
              <v-btn text color="blue darken-1" @click="save">
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              你确定要删除此供应商信息吗？
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete()">
                取消
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm()">
                确定
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRemark" max-width="500px">
          <v-form ref="remark">
            <v-card>
              <v-card-title class="headline">添加备注</v-card-title>
              <v-row dense class="mx-4">
                <v-col cols="12">
                  <v-textarea
                    label="备注"
                    auto-grow
                    rows="1"
                    v-model="currentRemark"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="blue darken-1" @click="closeRemark">
                  关闭
                </v-btn>
                <v-btn small dark color="blue darken-1" @click="saveRemark">
                  保存
                  <v-icon right dark>mdi-content-save</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text
                v-if="remarks.length > 0"
                class="pa-2 grey lighten-2"
              >
                <v-list class="py-0">
                  <v-subheader class="grey lighten-2">备注</v-subheader>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(remark, index) in remarks"
                      :key="index"
                    >
                      {{ remark.user_name }}({{ remark.create_date }})：
                      {{ remark.content }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>

              <v-overlay :absolute="true" :value="alert">
                <v-card color="orange darken-4" width="300px">
                  <v-card-title>失败</v-card-title>
                  <v-card-text>
                    {{ message }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange" @click="alert = false">
                      关闭
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-overlay>
              <v-overlay :absolute="true" :value="success">
                <v-card color="light-green darken-4" width="300px">
                  <v-card-title>成功</v-card-title>
                  <v-card-text>
                    {{ message }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="success = false">
                      关闭
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-overlay>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="mr-1" color="blue" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon class="mr-1" color="orange" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
      <v-icon color="pink lighten-3" title="备注" @click="openRemark(item)">
        mdi-chat
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { parseTime } from "@/utils";
import { baseUrl } from "../env";
import authHeader from "../services/auth-header";
import RemarkService from "../services/AddRemarkService";

export default {
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogRemark: false,
    loading: false,
    downloadLoading: false,
    headers: [
      { text: "代码", value: "code", align: "start" },
      { text: "缩写", value: "short_name" },
      { text: "名称", value: "name_cn" },
      { text: "地址", value: "address_cn", sortable: false },
      { text: "联系人", value: "contact" },
      {
        text: "操作",
        value: "actions",
        sortable: false,
        width: "120px",
        align: "center"
      }
    ],
    options: {},
    suppliers: [],
    selected: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      type: "SUPPLIER",
      code: "",
      short_name: "",
      name_cn: "",
      name_en: "",
      address_cn: "",
      address_en: "",
      contact: "",
      mobile_phone: "",
      phone: "",
      fax: "",
      email: "",
      website: "",
      invoice_flow: "",
      trade_terms: "",
      grade: 0
    },
    defaultItem: {
      id: 0,
      type: "SUPPLIER",
      code: "",
      short_name: "",
      name_cn: "",
      name_en: "",
      address_cn: "",
      address_en: "",
      contact: "",
      mobile_phone: "",
      phone: "",
      fax: "",
      email: "",
      website: "",
      invoice_flow: "",
      trade_terms: "",
      grade: 0
    },
    companyId: 0,
    alert: false,
    success: false,
    message: "",
    remarks: [],
    currentRemark: ""
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "新建供应商" : "编辑供应商";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      this.loading = true;
      fetch(baseUrl + "/api/companys/SUPPLIER", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          if (Response.ok) {
            return Response.json();
          } else {
            return Promise.reject({
              status: Response.status,
              statusText: Response.statusText
            });
          }
        })
        .then(jsonData => {
          this.suppliers = jsonData;
          this.loading = false;
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        });
    },

    editItem(item) {
      this.editedIndex = this.suppliers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.suppliers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      fetch(baseUrl + "/api/company/" + this.editedItem.id, {
        method: "delete",
        headers: authHeader()
      }).then(function(Response) {
        console.log(Response);
      });
      this.suppliers.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // 编辑供应商信息
        fetch(baseUrl + "/api/company/" + this.editedItem.id, {
          method: "put",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        Object.assign(this.suppliers[this.editedIndex], this.editedItem);
      } else {
        // 新建供应商信息
        fetch(baseUrl + "/api/company", {
          method: "post",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        this.suppliers.push(this.editedItem);
      }
      this.close();
    },

    export2Excel() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then(excel => {
        const tHeader = [
          "代码",
          "缩写",
          "中文名称",
          "英文名称",
          "中文地址",
          "英文地址",
          "联系人",
          "手机",
          "电话",
          "传真",
          "电子邮箱",
          "网址"
        ];
        const filterVal = [
          "code",
          "short_name",
          "name_cn",
          "name_en",
          "address_cn",
          "address_en",
          "contact",
          "mobile_phone",
          "phone",
          "fax",
          "email",
          "website"
        ];
        if (this.selected.length == 0) {
          this.selected = this.suppliers;
        }
        const list = this.selected;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "供应商资料",
          autoWidth: true,
          bookType: "xlsx"
        });
        this.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },

    openRemark(item) {
      this.companyId = item.id;
      this.currentRemark = "";
      RemarkService.getRemarks("COMPANY", this.companyId).then(response => {
        this.remarks = response.data;
      });
      this.dialogRemark = true;
    },

    closeRemark() {
      this.dialogRemark = false;
    },

    saveRemark() {
      if (this.currentRemark == "") {
        this.message = "请输入备注！";
        this.alert = true;
        return;
      }

      this.message = "";

      RemarkService.addRemark("COMPANY", this.companyId, this.currentRemark)
        .then(response => {
          this.success = true;
          this.message = response.data.message;
          return RemarkService.getRemarks("COMPANY", this.companyId);
        })
        .then(remarks => {
          this.remarks = remarks.data;
          this.currentRemark = "";
        })
        .catch(() => {
          this.alert = true;
          this.message = "添加备注失败！";
          this.currentRemark = "";
        });
    }
  }
};
</script>

<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="products"
    :search="search"
    :options.sync="options"
    :loading="loading"
    sort-by="code"
    class="elevation-1"
    item-key="id"
    show-select
    :footer-props="{
      showFirstLastPage: true
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>产品管理</v-toolbar-title>
        <v-divider inset vertical class="mx-4"></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="查找"
          single-line
          hide-details
        ></v-text-field>
        <v-divider inset vertical class="mx-4"></v-divider>
        <v-dialog v-model="dialogEdit" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle dense>
              <v-btn class="mb-2" v-bind="attrs" v-on="on">
                <v-icon color="green">mdi-file-outline</v-icon>
                新建
              </v-btn>
              <v-btn class="mb-2" disabled>
                <v-icon color="orange">mdi-delete</v-icon>
                删除
              </v-btn>
              <v-btn
                class="mb-2"
                :loading="downloadLoading"
                @click="export2Excel"
              >
                <v-icon color="blue">mdi-file-export-outline</v-icon>
                导出
              </v-btn>
            </v-btn-toggle>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.category"
                      label="类型"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.type"
                      label="子类"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.status"
                      label="状态"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.code"
                      label="编码"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.customer_code"
                      label="客户编码"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.supplier_code"
                      label="供应商编码"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.desc_en"
                      label="英文描述"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.desc_cn"
                      label="中文描述"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.material"
                      label="材质"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.method"
                      label="工艺"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="blue darken-1" @click="closeEdit">
                取消
              </v-btn>
              <v-btn text color="blue darken-1" @click="saveProduct">
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              你确定要删除此产品信息吗？
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                取消
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                确定
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPrice" max-width="500px">
          <v-card>
            <v-card-title class="headline">价格</v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-select
                      label="类型"
                      :items="priceType"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-file-tree-outline"
                      v-model="newPrice.type"
                      @change="typeChanged"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="来源"
                      :items="priceSource"
                      item-text="short_name"
                      item-value="id"
                      prepend-icon="mdi-factory"
                      v-model="newPrice.source_id"
                      @change="sourceChanged"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="客户"
                      :items="priceTarget"
                      item-text="short_name"
                      item-value="id"
                      prepend-icon="mdi-card-account-details-outline"
                      v-model="newPrice.target_id"
                      @change="targetChanged"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="条款"
                      :items="priceTerms"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-ballot-outline"
                      v-model="newPrice.terms"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      label="币种"
                      :items="priceCurrency"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-cash-marker"
                      v-model="newPrice.currency"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="单价"
                      prepend-icon="mdi-calculator"
                      v-model="newPrice.price"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-menu
                      v-model="menuDateFrom"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newPrice.datefrom"
                          label="起始日期"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newPrice.datefrom"
                        @input="menuDateFrom = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-menu
                      v-model="menuDateTo"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newPrice.dateto"
                          label="截止日期"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newPrice.dateto"
                        @input="menuDateTo = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="交货期"
                      prepend-icon="mdi-calendar-clock"
                      v-model="newPrice.leadtime"
                      suffix="天"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small dark color="blue darken-1" @click="closePrice">
                关闭
              </v-btn>
              <v-btn small dark color="blue darken-1" @click="savePrice">
                保存
                <v-icon right dark>mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text v-if="prices.length > 0" class="pa-2 grey lighten-2">
              <v-list class="py-0">
                <v-subheader class="grey lighten-2">价格列表</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(price, index) in prices" :key="index">
                    <v-icon
                      title="采购价"
                      color="green darken-2"
                      dark
                      v-if="price.type == 'PURCHASE'"
                    >
                      mdi-alpha-p-box
                    </v-icon>
                    <v-icon
                      title="FT价"
                      color="orange darken-2"
                      dark
                      v-if="
                        price.type == 'SELL' && price.source == '方田进出口'
                      "
                    >
                      mdi-alpha-f-box
                    </v-icon>
                    <v-icon
                      title="BTY价"
                      color="blue darken-2"
                      dark
                      v-if="price.type == 'SELL' && price.source == 'BTY贸易'"
                    >
                      mdi-alpha-b-box
                    </v-icon>
                    {{ price.source }} >>> {{ price.target }}：
                    <v-spacer></v-spacer>
                    ({{ price.terms }} {{ price.currency }}){{ "\xa0" }}
                    <b>{{ price.price }}</b>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
          <v-overlay :absolute="true" :value="message.flag">
            <v-card :color="message.color" width="300px">
              <v-card-title>{{ message.title }}</v-card-title>
              <v-card-text>
                {{ message.text }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="message.flag = false">
                  关闭
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-overlay>
        </v-dialog>
        <v-dialog v-model="dialogHscode" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              海关信息
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="hscode.code"
                      label="代码"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="hscode.desc_en"
                      label="英文描述"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="hscode.desc_cn"
                      label="中文描述"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="hscode.factors"
                      label="申报要素"
                      auto-grow
                      rows="1"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="blue darken-1" @click="closeHscode">
                关闭
              </v-btn>
              <v-btn text color="blue darken-1" @click="saveHscode">
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-overlay :absolute="true" :value="message.flag">
            <v-card :color="message.color" width="300px">
              <v-card-title>{{ message.title }}</v-card-title>
              <v-card-text>
                {{ message.text }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="message.flag = false">
                  关闭
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-overlay>
        </v-dialog>

        <v-dialog v-model="dialogRemark" max-width="500px">
          <v-card>
            <v-card-title class="headline">添加备注</v-card-title>
            <v-row dense class="mx-4">
              <v-col cols="12">
                <v-textarea
                  label="备注"
                  auto-grow
                  rows="1"
                  v-model="currentRemark"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small dark color="blue darken-1" @click="closeRemark">
                关闭
              </v-btn>
              <v-btn small dark color="blue darken-1" @click="saveRemark">
                保存
                <v-icon right dark>mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text v-if="remarks.length > 0" class="pa-2 grey lighten-2">
              <v-list class="py-0">
                <v-subheader class="grey lighten-2">备注</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(remark, index) in remarks" :key="index">
                    {{ remark.user_name }}({{ remark.create_date }})：
                    {{ remark.content }}
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>

            <v-overlay :absolute="true" :value="message.flag">
              <v-card :color="message.color" width="300px">
                <v-card-title>{{ message.title }}</v-card-title>
                <v-card-text>
                  {{ message.text }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="message.flag = false">
                    关闭
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-overlay>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        title="编辑基本信息"
        class="mr-1"
        color="blue"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon title="价格" class="mr-1" color="green" @click="openPrice(item)">
        mdi-cash-100
      </v-icon>
      <v-icon
        class="mr-1"
        title="备注"
        color="pink lighten-3"
        @click="openRemark(item)"
      >
        mdi-chat
      </v-icon>
      <v-icon title="详细" class="mr-1" color="teal" @click="openDetail(item)">
        mdi-details
      </v-icon>
      <v-icon
        title="海关信息"
        class="mr-1"
        color="purple lighten-3"
        @click="openHscode(item)"
      >
        mdi-alpha-h-circle
      </v-icon>
      <v-icon title="删除" color="orange" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { parseTime } from "@/utils";
import { baseUrl } from "../env";
import authHeader from "../services/auth-header";
import RemarkService from "../services/AddRemarkService";

export default {
  data: () => ({
    search: "",
    dialogEdit: false,
    dialogDelete: false,
    dialogRemark: false,
    dialogHscode: false,
    dialogPrice: false,
    loading: false,
    downloadLoading: false,
    headers: [
      { text: "类型", value: "category" },
      { text: "子类", value: "type" },
      { text: "编码", value: "code" },
      { text: "客户编码", value: "customer_code" },
      { text: "供应商编码", value: "supplier_code" },
      { text: "英文描述", value: "desc_en" },
      { text: "中文描述", value: "desc_cn" },
      { text: "采购价", value: "price" },
      { text: "供应商", value: "short_name" },
      { text: "状态", value: "status" },
      {
        text: "操作",
        value: "actions",
        sortable: false,
        width: "200px",
        align: "center"
      }
    ],
    options: {},
    products: [],
    selected: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      category: "",
      type: "",
      code: "",
      customer_code: "",
      supplier_code: "",
      desc_en: "",
      desc_cn: "",
      material: "",
      method: "",
      weight: 0,
      volume: 0,
      specs: "",
      qtyin20: 0,
      status: ""
    },
    defaultItem: {
      id: 0,
      category: "",
      type: "",
      code: "",
      customer_code: "",
      supplier_code: "",
      desc_en: "",
      desc_cn: "",
      material: "",
      method: "",
      weight: 0,
      volume: 0,
      specs: "",
      qtyin20: 0,
      status: ""
    },
    newHscode: false,
    hscode: {
      pd_id: 0,
      code: "",
      desc_en: "",
      desc_cn: "",
      factors: ""
    },
    productId: 0,
    message: {
      flag: false,
      title: "",
      color: "",
      text: ""
    },
    remarks: [],
    currentRemark: "",
    priceType: [
      { text: "采购价", value: "PURCHASE" },
      { text: "销售价", value: "SELL" }
    ],
    priceSource: [],
    priceTarget: [],
    priceTerms: [
      { text: "CIF", value: "CIF" },
      { text: "FOB", value: "FOB" },
      { text: "DDU", value: "DDU" }
    ],
    priceCurrency: [
      { text: "CNY", value: "CNY" },
      { text: "USD", value: "USD" },
      { text: "AUD", value: "AUD" }
    ],
    menuDateFrom: false,
    menuDateTo: false,
    newPrice: {
      type: "",
      source_id: null,
      source: null,
      target_id: null,
      target: null,
      terms: "",
      currency: "",
      price: 0,
      datefrom: "",
      dateto: "2099-12-31",
      leadtime: 0,
      status: "有效"
    },
    prices: []
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "新建产品" : "编辑产品";
    }
  },

  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    getDataFromApi() {
      this.loading = true;
      fetch(baseUrl + "/api/products", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          if (Response.ok) {
            return Response.json();
          } else {
            return Promise.reject({
              status: Response.status,
              statusText: Response.statusText
            });
          }
        })
        .then(jsonData => {
          this.products = jsonData.rows;
          this.loading = false;
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        });
    },

    getSuppliers() {
      fetch(baseUrl + "/api/companys/SUPPLIER", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.priceSource = jsonData;
        });
    },

    async getCustomers() {
      const response = await fetch(baseUrl + "/api/companys/CUSTOMER", {
        method: "get",
        headers: authHeader()
      });
      const customers = await response.json();
      return customers;
    },

    getFtmount() {
      fetch(baseUrl + "/api/companys/FTMOUNT", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.priceSource = jsonData;
        });
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      fetch(baseUrl + "/api/product/" + this.editedItem.id, {
        method: "delete",
        headers: authHeader()
      }).then(function(Response) {
        console.log(Response);
      });
      this.products.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveProduct() {
      if (this.editedIndex > -1) {
        fetch(baseUrl + "/api/product/" + this.editedItem.id, {
          method: "put",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        Object.assign(this.products[this.editedIndex], this.editedItem);
      } else {
        fetch(baseUrl + "/api/product", {
          method: "post",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        this.products.push(this.editedItem);
      }
      this.closeEdit();
    },

    export2Excel() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then(excel => {
        const tHeader = [
          "类型",
          "子类",
          "产品编码",
          "客户编码",
          "供应商编码",
          "英文描述",
          "中文描述",
          "材质",
          "工艺",
          "重量",
          "体积",
          "规格",
          "装箱数",
          "状态",
          "供应商",
          "采购价"
        ];
        const filterVal = [
          "category",
          "type",
          "code",
          "customer_code",
          "supplier_code",
          "desc_en",
          "desc_cn",
          "material",
          "method",
          "weight",
          "volume",
          "specs",
          "qtyin20",
          "status",
          "source",
          "price"
        ];
        if (this.selected.length == 0) {
          this.selected = this.products;
        }
        const list = this.selected;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "产品资料",
          autoWidth: true,
          bookType: "xlsx"
        });
        this.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },

    openPrice(item) {
      var today = new Date();
      this.productId = item.id;
      this.newPrice.type = "";
      this.newPrice.source_id = null;
      this.newPrice.target_id = null;
      this.newPrice.terms = "";
      this.newPrice.currency = "CNY";
      this.newPrice.price = 0;
      this.newPrice.datefrom =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.newPrice.dateto = "2099-12-31";
      fetch(baseUrl + "/api/price/" + this.productId, {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.prices = jsonData;
        });
      this.dialogPrice = true;
    },

    closePrice() {
      this.dialogPrice = false;
    },

    savePrice() {
      if (this.newPrice.type == "") {
        this.message.text = "请选择价格类型！";
        this.message.title = "提示";
        this.message.color = "yellow darken-3";
        this.message.flag = true;
        return;
      }

      if (this.newPrice.source_id === null) {
        this.message.text = "请选择价格来源！";
        this.message.title = "提示";
        this.message.color = "yellow darken-3";
        this.message.flag = true;
        return;
      }

      if (this.newPrice.price == 0) {
        this.message.text = "请输入价格！";
        this.message.title = "提示";
        this.message.color = "yellow darken-3";
        this.message.flag = true;
        return;
      }

      this.message.text = "";

      fetch(baseUrl + "/api/price/" + this.productId, {
        method: "post",
        headers: authHeader(),
        body: JSON.stringify(this.newPrice)
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          if (jsonData.status == "200") {
            this.message.text = jsonData.message;
            this.message.title = "成功";
            this.message.color = "success";
            this.prices.push(this.newPrice);
          } else {
            this.message.text = jsonData.error;
            this.message.title = "失败";
            this.message.color = "warning";
          }
        });
      this.message.flag = true;
    },

    typeChanged() {
      if (this.newPrice.type == "PURCHASE") {
        this.getSuppliers();
        this.newPrice.terms = "";
        this.newPrice.currency = "CNY";
      } else {
        this.getFtmount();
        this.newPrice.terms = "CIF";
        this.newPrice.currency = "USD";
      }
    },

    sourceChanged() {
      for (var i = 0; i < this.priceSource.length; i++) {
        if (this.priceSource[i].id == this.newPrice.source_id) {
          this.newPrice.source = this.priceSource[i].short_name;
          break;
        }
      }
      this.getCustomers().then(customers => {
        this.priceTarget = customers;
        if (this.newPrice.source == "方田进出口") {
          this.priceTarget.unshift(this.priceSource[1]);
        }
      });
    },

    targetChanged() {
      for (var i = 0; i < this.priceTarget.length; i++) {
        if (this.priceTarget[i].id == this.newPrice.target_id) {
          this.newPrice.target = this.priceTarget[i].short_name;
          break;
        }
      }
    },

    openHscode(item) {
      this.productId = item.id;
      fetch(baseUrl + "/api/hscode/" + this.productId, {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          if (jsonData) {
            this.newHscode = false;
            this.hscode = jsonData;
          } else {
            this.newHscode = true;
            this.hscode.pd_id = this.productId;
            this.hscode.code = "";
            this.hscode.desc_en = "";
            this.hscode.desc_cn = "";
            this.hscode.factors = "";
          }
        });
      this.dialogHscode = true;
    },

    closeHscode() {
      this.dialogHscode = false;
    },

    saveHscode() {
      if (this.newHscode) {
        fetch(baseUrl + "/api/hscode", {
          method: "post",
          headers: authHeader(),
          body: JSON.stringify(this.hscode)
        })
          .then(Response => {
            return Response.json();
          })
          .then(jsonData => {
            if (jsonData.status == "200") {
              this.message.text = jsonData.message;
              this.message.title = "成功";
              this.message.color = "success";
            } else {
              this.message.text = jsonData.error;
              this.message.title = "失败";
              this.message.color = "warning";
            }
          });
        this.message.flag = true;
      } else {
        fetch(baseUrl + "/api/hscode/" + this.productId, {
          method: "put",
          headers: authHeader(),
          body: JSON.stringify(this.hscode)
        })
          .then(Response => {
            return Response.json();
          })
          .then(jsonData => {
            if (jsonData.status == "200") {
              this.message.text = jsonData.message;
              this.message.title = "成功";
              this.message.color = "success";
            } else {
              this.message.text = jsonData.error;
              this.message.title = "成功";
              this.message.color = "warning";
            }
          });
        this.message.flag = true;
      }
    },

    openRemark(item) {
      this.productId = item.id;
      this.currentRemark = "";
      RemarkService.getRemarks("PRODUCT", this.productId).then(response => {
        this.remarks = response.data;
      });
      this.dialogRemark = true;
    },

    closeRemark() {
      this.dialogRemark = false;
    },

    saveRemark() {
      if (this.currentRemark == "") {
        this.message.text = "请输入备注！";
        this.message.title = "提示";
        this.message.color = "yellow darken-3";
        this.message.flag = true;
        return;
      }

      this.message.text = "";

      RemarkService.addRemark("PRODUCT", this.productId, this.currentRemark)
        .then(response => {
          this.message.text = response.data.message;
          this.message.title = "成功";
          this.message.color = "success";
          return RemarkService.getRemarks("PRODUCT", this.productId);
        })
        .then(remarks => {
          this.remarks = remarks.data;
          this.currentRemark = "";
        })
        .catch(() => {
          this.message.text = "添加备注失败！";
          this.message.title = "失败";
          this.message.color = "warning";
          this.currentRemark = "";
        });
      this.message.flag = true;
    },

    openDetail(item) {
      this.productId = item.id;
      this.$router.push("/product/" + this.productId);
    }
  }
};
</script>

import { baseUrl } from "../env";

class AuthService {
  login(user) {
    return fetch(baseUrl + "/api/auth/signin", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ username: user.username, password: user.password })
    })
      .then(Response => {
        if (Response.ok) {
          return Response.json();
        } else {
          return Promise.reject({
            status: Response.status,
            statusText: Response.statusText
          });
        }
      })
      .then(jsonData => {
        if (jsonData.accessToken) {
          localStorage.setItem("user", JSON.stringify(jsonData));
        }
        return jsonData;
      })
      .catch(error => console.log("error is", error));
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();

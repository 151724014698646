/**
 * baseUrl: 域名地址
 */

let baseUrl = "";

if (process.env.NODE_ENV == "development") {
  baseUrl = "http://localhost:8000";
} else {
  baseUrl = "https://oms.ftmount.cn";
}

export { baseUrl };

import http from "../http-common";

class AddRemarksService {
  addRemark(type, refId, content) {
    let formData = new FormData();
    let user = JSON.parse(localStorage.getItem("user"));

    formData.append("type", type);
    formData.append("ref_id", refId);
    formData.append("content", content);

    return http.post("/api/remark", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + user.accessToken
      }
    });
  }

  getRemarks(type, refId) {
    let user = JSON.parse(localStorage.getItem("user"));

    return http.get("/api/remark/" + type + "/" + refId, {
      headers: {
        Authorization: "Bearer " + user.accessToken
      }
    });
  }
}

export default new AddRemarksService();

<template>
  <v-app>
    <v-system-bar color="primary" dark app height="56" v-if="this.loggedIn">
      <div class="d-flex align-center">
        <!--<v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />-->
        <v-icon x-large color="orange darken-2">mdi-alpha-f-box-outline</v-icon>
      </div>

      <v-toolbar-title>FTMount Order Management System</v-toolbar-title>

      <v-spacer></v-spacer>

      <!--<v-btn
        href="http://ftmount.com"
        target="_blank"
        text
      >
        <span class="mr-2">注销</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>-->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon small color="cyan" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="menuOperation(index)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-system-bar>

    <v-navigation-drawer
      permanent
      expand-on-hover
      app
      width="192"
      v-if="this.loggedIn"
    >
      <v-list nav dense>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>工作台</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/orders">
          <v-list-item-icon>
            <v-icon>mdi-file-table-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>订单管理</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/products">
          <v-list-item-icon>
            <v-icon>mdi-cart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>产品管理</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/customers">
          <v-list-item-icon>
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>客户管理</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/suppliers">
          <v-list-item-icon>
            <v-icon>mdi-factory</v-icon>
          </v-list-item-icon>
          <v-list-item-title>供应商管理</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-warehouse</v-icon>
          </v-list-item-icon>
          <v-list-item-title>库存管理</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-truck-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>发货管理</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-chart-areaspline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>报表</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid style="height: 100vh;">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    items: [
      { icon: "mdi-account-cog-outline", text: "设置" },
      { icon: "mdi-desktop-classic", text: "服务器" },
      { icon: "mdi-email-outline", text: "Email" },
      { icon: "mdi-logout-variant", text: "退出" }
    ]
  }),

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    menuOperation(index) {
      if (index === 3) {
        this.logOut();
      }
    }
  }
};
</script>

import axios from "axios";
import { baseUrl } from "./env";

/*let baseUrl = "";

if (process.env.NODE_ENV == "development") {
  baseUrl = "http://localhost:8000";
} else {
  baseUrl = "https://oms.ftmount.cn";
}*/

export default axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json"
  }
});
